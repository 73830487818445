<template>
    <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
        Memuatkan senarai murid
    </loading-spinner>
    <assessment-details v-else-if="currentAssessment" :currentAssessment="currentAssessment" @update="updateAssessment"
        @close="viewAssessment" />
    <div v-else>
        <div class="d-flex justify-content-center mt-5">
            <div style="width: 240px">
                <canvas id="myChart"></canvas>
            </div>
        </div>
        <div class="mt-4">
            <div class="table-responsive rounded shadow-sm rounded border border-light position-relative">
                <table class="table lh-sm table-admin w-100">
                    <thead>
                        <th class="text-center">Tajuk</th>
                        <th v-if="$store.state.user.schoolAdmin" class="text-center">Jumlah Peserta yang Jawab</th>
                        <th v-else class="text-center">Jumlah Murid yang Jawab</th>
                        <th class="text-center">Purata Markah</th>
                    </thead>
                    <tbody>
                        <tr v-for="assessment in displayedData" :key="assessment._id" :ref="`assessment_${assessment._id}`">
                            <td class="text-nowrap text-truncate" style="font-weight: 500;">
                                <p :title="assessment.title" class="mb-0 clickable" @click="viewAssessment(assessment._id)">{{
                                    assessment.title
                                }}</p>
                            </td>
                            <td class="text-center" v-if="$store.state.user.schoolAdmin">
                                <span class="badge bg-light border text-dark fw-normal p-2">
                                    {{
                                        assessment.teacher_assessments ? assessment.teacher_assessments.length : 0
                                    }} / {{ dependants.length }}
                                </span>
                            </td>
                            <td class="text-center" v-else>
                                <span class="badge bg-light border text-dark fw-normal p-2">
                                    {{
                                        assessment.dependant_assessments ? assessment.dependant_assessments.length : 0
                                    }} / {{ dependants.length }}
                                </span>
                            </td>
                            <td class="text-center"  v-if="$store.state.user.schoolAdmin">
                                <span class="badge bg-warning border text-dark fw-bold p-2">
                                    {{ assessment.teacher_assessments ? average(assessment.teacher_assessments) : 0 }}
                                </span>
                            </td>
                            <td class="text-center" v-else>
                                <span class="badge bg-warning border text-dark fw-bold p-2">
                                    {{ assessment.dependant_assessments ? average(assessment.dependant_assessments) : 0 }}
                                </span>
                            </td>
                        </tr>
                        <tr v-if="computedAssessments.length == 0">
                            <td colspan="5" class="text-center fst-italic text-muted">
                                Tiada penilaian dijumpai
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import AssessmentDetails from "@/components/AssessmentDetails";
import Chart from 'chart.js/auto'

export default {
    components: { LoadingSpinner, AssessmentDetails },
    data() {
        return {
            isLoading: false,
            search: "",
            assessments: [],
            currentAssessment: null,
            currentPage: 1,
            itemsPerPage: 50,
            dependants: [],
        };
    },
    watch: {
        "$route.params": {
            handler() {
                this.handleParamChange();
            },
            deep: true,
            immediate: false,
        },
    },
    computed: {
        computedAssessments() {
            const search = this.search.toLowerCase();

            return this.assessments.filter((assessment) =>
                assessment.title.toLowerCase().includes(search)
            );
        },
        totalPages() {
            return Math.ceil(this.assessments.length / this.itemsPerPage);
        },
        displayedData() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            if (this.search != '') {
                return this.computedAssessments
            } else {
                return this.assessments.slice(startIndex, endIndex);
            }
        }
    },
    methods: {
        updateAssessment(e) {
            let assessment = this.assessments.find((d) => d._id == e._id);
            let assessmentIndex = this.assessments.findIndex((d) => d._id == e._id);

            if (assessment) {
                this.$set(this.assessments, assessmentIndex, e);

                this.handleParamChange();
            }
        },
        viewAssessment(assessmentId, view) {
            let route = {
                name: this.$route.name,
                params: {
                    id: assessmentId,
                },
                query: {},
            };

            if (view) {
                route.query["view"] = view;
            }

            this.$router.push(route);
        },
        handleParamChange() {
            if (this.$route.params.id) {
                this.currentAssessment = this.assessments.find(
                    (assessment) => assessment._id == this.$route.params.id
                );
            } else {
                this.currentAssessment = null;
            }
        },
        average(assessments) {
            let data = []
            assessments.forEach(assessment => {
                data.push(assessment.marks)
            });

            let totalMarks = 0

            for (const mark of data) {
                totalMarks += mark;
            }

            const averageMark = totalMarks / assessments.length;

            return Math.round(averageMark * 100) / 100
        },
        goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.search = '';
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.search = '';
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.search = '';
            }
        },
        async getAssessments() {
            this.isLoading = true;

            return new Promise(async (resolve) => {
                try {
                    const { data } = await this.API.get(
                        `assessments?_limit=-1`
                    );

                    this.assessments = data;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isLoading = false;

                    resolve();
                }
            });
        },
        async getDependants() {
            this.isLoading = true;

            return new Promise(async (resolve) => {
                try {
                    const { data } = await this.API.get(
                        `dependants?_limit=-1`
                    );

                    this.dependants = data;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isLoading = false;

                    resolve();
                }
            });
        },
        async getTeachers() {
            this.isLoading = true;

            return new Promise(async (resolve) => {
                try {
                    const { data } = await this.API.get(
                        `users?_limit=-1&isStudent=false`
                    );

                    this.dependants = data;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isLoading = false;

                    resolve();
                }
            });
        },
    },
    async mounted() {
        let waitUserAvailable = async () => {
            this.isLoading = true;

            if (this.$store.state.user) {
                await this.getAssessments();
                if(this.$store.state.user.schoolAdmin){
                    await this.getTeachers(); 
                } else {
                    await this.getDependants(); 
                }

                this.isLoading = false;

                this.handleParamChange();

                const semua = this.dependants.length
                let dahJawab = ''
                if(this.$store.state.user){
                    dahJawab = this.assessments[0].teacher_assessments.length
                } else {
                    dahJawab = this.assessments[0].dependant_assessments.length
                }
                
                const belumJawab = semua - dahJawab

                new Chart(document.getElementById('myChart'), {
                type: 'pie',
                data: {
                    labels: [
                        'Jawab',
                        'Belum Jawab',
                    ],
                    datasets: [{
                        data: [dahJawab, belumJawab],
                        backgroundColor: [
                            '#008000',
                            '#FF0000',
                        ],
                        hoverOffset: 4
                    }]
                }
            });
            } else {
                setTimeout(() => {
                    waitUserAvailable();
                }, 1000);
            }
        };

        waitUserAvailable();
    },
};
</script>
<template>
    <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
      Loading assessment details
    </loading-spinner>
    <div v-else-if="!isLoading">
      <div class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center">
        <button class="btn btn-sm py-0 me-3 lh-1" @click="$emit('close')" v-if="!$store.state.user.isStudent">
          <i class="fas fa-arrow-left text-light" />
        </button>
        <p class="mb-0 text-truncate fw-medium lh-1" v-if="currentAssessment">
          {{ currentAssessment.title }}
        </p>
        <span class="text-muted fst-italic" v-else>Tiada penilaian dipilih</span>
      </div>
      <div class="py-3 text-nowrap overflow-auto no-scrollbar" v-if="currentAssessment">
        <div class="pb-3 d-flex justify-content-between align-items-center">
          <div class="input-group">
            <span class="input-group-text bg-white">
              <i class="fas fa-search" />
            </span>
            <input type="text" class="form-control border-start-0 ps-0 no-focus"
              :class="{ 'border-end-0': search.length > 0 }" placeholder="Cari murid" v-model="search" />
            <button class="btn bg-white border border-light" type="button" v-if="search.length > 0" @click="search = ''">
              &times;
            </button>
          </div>
        </div>
        <div class="
            table-responsive
            rounded
            shadow-sm
            rounded
            border border-light
            position-relative
          ">
          <table class="table lh-sm table-admin w-100">
            <thead>
              <tr>
                <th class="pe-0">Nama</th>
                <th class="text-center">Kelas</th>
                <th class="text-center">Sekolah</th>
                <th class="text-center">Markah</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cls in computedDependants" :key="cls._id">
                <td class="text-nowrap text-truncate pe-0">
                  <span class="
                      p-0
                      w-100
                      lh-sm
                      text-start text-decoration-none text-truncate
                    " :title="cls.dependant.name" v-html="cls.dependant.name"></span>
                </td>
                <td class="text-nowrap text-center text-truncate pe-0">
                  {{ className(cls.dependant.class) }}
                </td>
                <td :title="schoolName(cls.dependant.class)" class="text-nowrap text-center text-truncate pe-0" style="cursor: pointer;">
                  {{ schoolName(cls.dependant.class) }}
                </td>
                <td class="text-center">
                    <span class="badge bg-warning border text-dark fw-bold p-2">
                        {{ cls.marks + '%' }}
                    </span>
                </td>
              </tr>
              <tr v-if="computedDependants.length < 1">
                <td colspan="8" class="text-center fst-italic text-muted">
                  No class found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import LoadingSpinner from "@/components/LoadingSpinner";
  
  export default {
    props: ["currentAssessment"],
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        isLoading: false,
        currentMedia: null,
        dependants: [],
        classes: [],
        search: "",
      };
    },
    watch: {
      currentAssessment: {
        async handler() {
          if (this.currentAssessment) {
            if (
              this.currentAssessment.dependant_assessments &&
              this.currentAssessment.dependant_assessments.length > 0
            ) {
              await this.getDependants();
              await this.getClass();
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      computedDependants() {
        const search = this.search.toLowerCase();
  
        let result = [...this.dependants];
  
        return result.filter((enquiry) =>
          enquiry.dependant.name.toLowerCase().includes(search)
        );
      },
      className() {
        return (classId) => {
          const cls = this.classes.find((cls) => cls._id == classId);
  
          if (cls) {
            return cls.name;
          } else {
            return null;
          }
        };
      },
      schoolName() {
        return (classId) => {
          const cls = this.classes.find((cls) => cls._id == classId);
  
          if (cls) {
            return cls.school.name;
          } else {
            return null;
          }
        };
      },
    },
    methods: {
      async getDependants() {
        this.isLoading = true;
  
        return new Promise(async (resolve) => {
          try {
            const { data } = await this.API.get(
              `dependant-assessments?_limit=-1&assessment._id=${this.currentAssessment._id}&_sort=marks:DESC`
            );
  
            this.dependants = data;
          } catch (error) {
            console.error(error);
          } finally {
            this.isLoading = false;
  
            resolve();
          }
        });
      },
      async getClass() {
        this.isLoading = true;
  
        return new Promise(async (resolve) => {
          try {
            const { data } = await this.API.get(
              `classes?_limit=-1`
            );
  
            this.classes = data;
          } catch (error) {
            console.error(error);
          } finally {
            this.isLoading = false;
  
            resolve();
          }
        });
      },
    },
    mounted() {
      if (this.$route.query && this.$route.query.view) {
        this.currentView = this.$route.query.view;
      }
    },
  };
  </script>